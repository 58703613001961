import React, { useEffect } from "react";
import { Admin, Resource, Loading } from "react-admin";
import ApolloClient from "apollo-boost";
import { useAuth0 } from "@auth0/auth0-react";

import { buildCustomDataProvider } from "./dataProvider";
import authProvider from "./authProvider";
import { UserEdit, UserList, UserShow } from "./components/users";
import { RuleCreate, RuleEdit, RuleList } from "./components/rules";
import { LogList, LogShow } from "./components/logs";
import { DeviceList, DeviceShow } from "./components/devices";
import { theme } from "./theme";
import { RoleCreate, RoleEdit, RoleList } from "./components/roles";
import { ExchangeCreate, ExchangeEdit, ExchangeList } from "./components/exchanges";
import { LogTraceList } from "./components/logTraces";
import { VideoCreate, VideoEdit, VideoList } from "./components/videos";

const hasuraUrl = "https://user-api.crowdpoint.tech/v1/graphql";

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const customAuthProvider = authProvider({
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
  });
  const [dataProvider, setDataProvider] = React.useState<any>(undefined);

  console.log("isLoading", isLoading);
  console.log("user", user);
  console.log("isAuthenticated", isAuthenticated);
  console.log("error", error);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(async (token: string) => {
          console.log("token", token);
          const client = new ApolloClient({
            uri: hasuraUrl,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const dp = await buildCustomDataProvider(client);
          setDataProvider(dp);
        })
        .catch((tokenError) => {
          console.log(tokenError);
        });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading || !isAuthenticated || !dataProvider) {
    return (
      <Loading
        loadingPrimary="CrowdPoint Technology Admin"
        loadingSecondary="Loading"
      />
    );
  }

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={customAuthProvider}
      theme={theme}
    >
      <Resource name="users" list={UserList} edit={UserEdit} show={UserShow} />
      <Resource name="devices" list={DeviceList} show={DeviceShow} />
      <Resource
        name="rules"
        list={RuleList}
        edit={RuleEdit}
        create={RuleCreate}
      />
      {/* <Resource
        name="roles"
        list={RoleList}
        edit={RoleEdit}
        create={RoleCreate}
      />
      <Resource
        name="exchanges"
        list={ExchangeList}
        edit={ExchangeEdit}
        create={ExchangeCreate}
      /> */}
      <Resource name="logTraces" list={LogTraceList} />
      <Resource name="logs" list={LogList} show={LogShow} />
      <Resource
        name="videos"
        list={VideoList}
        edit={VideoEdit}
        create={VideoCreate}
      />
      <Resource name="userRoles" />
      <Resource name="roles" />
      <Resource name="exchanges" />
    </Admin>
  );
};

export default App;
