import React from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import CodeInput from "./CodeInput";

export const RuleList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="data" label="Rule" />
      <ReferenceField source="roleId" reference="roles">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export const RuleCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <CodeInput
        source="data"
        parse={(v: any) => JSON.parse(v)}
        format={(v: any) => JSON.stringify(v, null, 2)}
        label="Rule"
        mode="json"
        {...props}
      />
      <ReferenceInput label="Role" source="roleId" reference="roles">
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const RuleEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <CodeInput
        source="data"
        parse={(v: any) => JSON.parse(v)}
        format={(v: any) => JSON.stringify(v, null, 2)}
        label="Rule"
        mode="json"
        {...props}
      />
      <ReferenceInput label="Role" source="roleId" reference="roles">
        <SelectInput />
      </ReferenceInput>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleForm>
  </Edit>
);
