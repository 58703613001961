import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Edit,
  EditButton,
  Filter,
  Record,
  Show,
  SimpleForm,
  TextInput,
  TabbedShowLayout,
  Tab,
  Identifier,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
} from "react-admin";
import { useHistory } from "react-router-dom";

import { UserRoleList } from "./userRoles";
import CodeField from "./CodeField";

const useStyles = makeStyles({
  deviceRowCell: {
    maxWidth: 300,
    overflow: "hidden",
  },
});

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const UserTitle = ({ record }: { record: Record }) => {
  return <span>User {record ? `"${record.email}" ` : " "}</span>;
};

export const UserList = (props: any) => {
  return (
    <List filters={<UserFilter />} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show">
        <TextField source="email" />
        <TextField source="auth0Data" />
        <ReferenceManyField label="Roles" reference="userRoles" target="userId">
          <SingleFieldList>
            <ChipField source="role.name" />
          </SingleFieldList>
        </ReferenceManyField>
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const UserShow = (props: any) => (
  <Show {...props} title={<UserTitle {...props} />}>
    <TabbedShowLayout>
      <Tab label="User">
        <UserEdit {...props} />
      </Tab>
      <Tab label="Roles">
        <UserRoleList {...props} />
      </Tab>
      <Tab label="Devices">
        <UserDeviceList />
      </Tab>
      <Tab label="Logs">
        <UserLogList />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const UserEdit = (props: any) => {
  console.log(props.record);
  return (
    <Edit title=" " {...props} hasShow={false}>
      <SimpleForm>
        <TextField source="email" />
        <CodeField 
          source="auth0Data" 
          format={(v: any) => JSON.stringify(v, null, 2)} 
        />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
        <TextField source="id" />
      </SimpleForm>
    </Edit>
  );
};

export const UserDeviceList = (props: any) => {
  const classes = useStyles();
  const overrides = {
    basePath: "/devices",
    resource: "devices",
    hasList: true,
    hasEdit: false,
    hasShow: true,
    hasCreate: false,
  };

  return (
    <List {...props} {...overrides} filter={{ userId: props.record.id }}>
      <Datagrid
        rowClick="show"
        optimized
        classes={{ rowCell: classes.deviceRowCell }}
      >
        <TextField source="type" />
        <TextField source="name" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
        <TextField source="deviceKey" />
      </Datagrid>
    </List>
  );
};

export const UserLogList = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const overrides = {
    basePath: "/logTraces",
    resource: "logTraces",
    hasList: true,
    hasEdit: false,
    hasShow: true,
    hasCreate: false,
  };

  const rowClick = (id: Identifier, basePath: string, record: Record) => {
    history.push(`/logs?trace=${record.id}`);
    return "";
  };

  return (
    <List {...props}
      {...overrides}
      filter={{ userId: props.record.id }}
      sort={{ field: 'minTimestamp', order: 'DESC' }}
    >
      <Datagrid
        rowClick={rowClick}
        optimized
        classes={{ rowCell: classes.deviceRowCell }}
      >
        <DateField source="minTimestamp" showTime />
        <DateField source="maxTimestamp" showTime />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
};
