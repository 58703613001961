import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useRefresh,
} from "react-admin";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

export const UserRoleList = (props: any) => {
  const [editItem, setEditItem] = useState<any>(null);
  const [showCreate, setShowCreate] = useState(false);
  const onCreateCancel = () => {
    setShowCreate(false);
  };
  const onEditCancel = () => {
    setEditItem(null);
  };

  const onRowClick = (id: string, basePath: string, record: any) => {
    setEditItem({ id, basePath, record });
  };

  const overrides = {
    basePath: "/userRoles",
    resource: "userRoles",
    hasList: true,
    hasEdit: false,
    hasShow: true,
    hasCreate: true,
  };

  const userId = props.record?.id;

  // override create button so it doesn't follow a route
  const Actions = () => (
    <TopToolbar>
      <CreateButton />
    </TopToolbar>
  );

  const CreateButton = () => (
    <Button color="primary" onClick={() => setShowCreate(true)}>
      <AddIcon />
      <Typography>Add</Typography>
    </Button>
  );

  return (
    <>
      <List
        {...props}
        {...overrides}
        title=" Roles"
        actions={<Actions />}
        filter={{ userId }}
        empty={
          <>
            <span>
              Add a Role <CreateButton />
            </span>
          </>
        }
      >
        <Datagrid>
          <ReferenceField source="roleId" reference="roles" label="Role">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="exchangeId" reference="exchanges" label="Exchange">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="id" />
          <DateField source="createdAt" />
        </Datagrid>
      </List>
      <Drawer open={showCreate} anchor="right" onClose={onCreateCancel}>
        <UserRoleCreate {...props} onCancel={onCreateCancel} userId={userId} />
      </Drawer>
    </>
  );
};

export const UserRoleCreate = (props: any) => {
  const { userId, record, onCancel, ...rest } = props;
  const refresh = useRefresh();
  const overrides = {
    basePath: "/userRoles",
    resource: "userRoles",
    title: " ",
    onSuccess: () => {
      refresh();
      onCancel();
    },
  };

  return (
    <Create {...rest} {...overrides}>
      <SimpleForm>
        <ReferenceInput
          disabled
          label="User"
          source="userId"
          reference="users"
          initialValue={userId}
        >
          <SelectInput />
        </ReferenceInput>
        <ReferenceInput label="Role" source="roleId" reference="roles">
          <SelectInput />
        </ReferenceInput>
        <ReferenceInput label="Exchange" source="exchangeId" reference="exchanges">
          <SelectInput />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
