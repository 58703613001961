import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  SimpleShowLayout,
  Show,
  TextField,
  TextInput,
} from "react-admin";
import queryString from "query-string";

const LogFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Trace" source="trace" alwaysOn />
    <TextInput lavel="Level" source="level" alwaysOn />
  </Filter>
);

export const LogList = (props: any) => {
  const query = queryString.parse(props.location.search);
  const defaultFilter = query.trace ? {trace: query.trace} : undefined;
  return (
    <List 
      filters={<LogFilter />} 
      filterDefaultValues={defaultFilter} 
      bulkActionButtons={false} 
      sort={{ field: 'timestamp', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="level" />
        {/* <TextField source="details.message" label="Message" /> */}
        <DetailsMessageField />
        <DateField source="timestamp" showTime />
        <TextField source="trace" />
        <ReferenceField source="userId" reference="users">
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}

const DetailsMessageField = (props: any) => {
  const maxLength = 37;
  const { record } = props;
  const message = record.details.message;
  return message != undefined ? (
    <span>
      {message.length > maxLength
        ? message.slice(0, maxLength - 3) + "..."
        : message}
    </span>
  ) : null;
};

export const LogShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="level" />
      <TextField source="details.message" label="Message" />
      <TextField source="service" />
      <DateField source="timestamp" showTime />
      <TextField source="trace" />
      <ReferenceField source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);
