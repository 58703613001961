import buildHasuraProvider from "ra-data-hasura-graphql";
import * as gqlTypes from "graphql-ast-types-browser";

import { buildQueryFactory } from "./lib/ra-data-hasura-graphql/buildQuery";
import buildVariables from "./lib/ra-data-hasura-graphql/buildVariables";
import {
  buildGqlQuery,
  buildFields,
  buildMetaArgs,
  buildArgs,
  buildApolloArgs,
} from "./lib/ra-data-hasura-graphql/buildGqlQuery";
import getResponseParser from "./lib/ra-data-hasura-graphql/getResponseParser";

const queryWithUserRoleRelationships = () => {
  const buildFieldsCustom = (type: any) => {
    let res = buildFields(type);
    if (type.name === "userRoles") {
      res.push(
        gqlTypes.field(
          gqlTypes.name("role"),
          null,
          null,
          null,
          gqlTypes.selectionSet([
            gqlTypes.field(gqlTypes.name("id")),
            gqlTypes.field(gqlTypes.name("name")),
          ])
        )
      );
    }
    if (type.name === "users") {
      res.push(
        gqlTypes.field(
          gqlTypes.name("userRoles"),
          null,
          null,
          null,
          gqlTypes.selectionSet([
            gqlTypes.field(
              gqlTypes.name("role"),
              null,
              null,
              null,
              gqlTypes.selectionSet([
                gqlTypes.field(gqlTypes.name("name")),
              ])),
          ])
        )
      );
    }
    return res;
  };
  const buildGqlQueryCustom = (iR: any) =>
    buildGqlQuery(
      iR,
      buildFieldsCustom,
      buildMetaArgs,
      buildArgs,
      buildApolloArgs
    );
  return buildQueryFactory(
    buildVariables,
    buildGqlQueryCustom,
    getResponseParser
  );
};

const convertFileToBase64 = (file: any): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

export const buildCustomDataProvider = async (client: any) => {
  const dp = await buildHasuraProvider({ client, buildQuery: queryWithUserRoleRelationships() });
  return () => dp;
};

const emptyHandleRequest = (type: string, resource: string, params: any) => {
  if (type.includes("LIST") || type.includes("MANY")) {
    return Promise.resolve({ data: [], total: 0 });
  }
  return Promise.resolve({ data: {} });
};

const dataProviderWithHandler = (
  handleRequest: (type: string, resource: string, params: any) => any
) => {
  return {
    getList: (resource: string, params: any) =>
      handleRequest("GET_LIST", resource, params),
    getOne: (resource: string, params: any) =>
      handleRequest("GET_ONE", resource, params),
    getMany: (resource: string, params: any) =>
      handleRequest("GET_MANY", resource, params),
    getManyReference: (resource: string, params: any) =>
      handleRequest("GET_MANY_REFERENCE", resource, params),
    update: (resource: string, params: any) =>
      handleRequest("UPDATE", resource, params),
    updateMany: (resource: string, params: any) =>
      handleRequest("UPDATE_MANY", resource, params),
    create: (resource: string, params: any) =>
      handleRequest("CREATE", resource, params),
    delete: (resource: string, params: any) =>
      handleRequest("DELETE", resource, params),
    deleteMany: (resource: string, params: any) =>
      handleRequest("DELETE_MANY", resource, params),
  };
};

export const emptyDataProvider = () => {
  return dataProviderWithHandler(emptyHandleRequest);
}
