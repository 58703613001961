import React, { useState } from "react";
import AceEditor from "react-ace";

import { ChangeEvent, FocusEvent } from 'react';
import { Typography } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { useInput, Validator } from "react-admin";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-github";

interface CodeInputProps<T = any> {
  value?: string;
  record: any;
  resource?: string;
  source: string;
  label: string;
  parse: (v: string) => any;
  format: (v: any) => string;
  onBlur?: (event: FocusEvent<T>) => void;
  onChange?: (event: ChangeEvent | any) => void;
  onFocus?: (event: FocusEvent<T>) => void;
  mode?: string;
  validate?: Validator | Validator[];
}

export default (props: CodeInputProps) => {
  const { source, label, record, resource, parse, format, mode, onBlur, onChange, onFocus, validate, ...rest } = props;

  const [parseError, setParseError] = useState<Error | undefined>(undefined);
  
  const {
    isRequired,
    input,
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });

  const [currentValue, setCurrentValue] = useState(input.value);

  const onCodeChange = (v: string) => {
    setCurrentValue(v);
    try {
      parse(v);
      input.onChange(v);
      setParseError(undefined);
    } catch(error) {
      setParseError(error);
    }
  }

  return (
    <div>
      <Typography
        variant="caption"
        color="textSecondary"
      >
        {label || source}
      </Typography>
      
      <AceEditor
        style={{ width: "100%", minWidth: 400 }}
        mode={mode}
        theme="github"
        minLines={20}
        maxLines={40}
        wrapEnabled={true}
        editorProps={{ $blockScrolling: true }}
        value={currentValue}
        onChange={onCodeChange}
      />

      { parseError ? 
        <Alert severity="error">{parseError.message}</Alert> 
        : null
      }
    </div>
  );
};
