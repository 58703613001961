import React from "react";
import {
  List,
  Create,
  DateField,
  Datagrid,
  TextField,
  TextInput,
  SimpleForm,
  Edit,
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';

export const VideoList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="url" />
      <TextField source="description" />
      <TextField source="category" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export const VideoCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" fullWidth/>
      <RichTextInput source="description" />
      <TextInput source="url" fullWidth/>
      <TextInput source="category" fullWidth/>
    </SimpleForm>
  </Create>
);

export const VideoEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
    <TextInput source="title" fullWidth/>
      <RichTextInput source="description" />
      <TextInput source="url" fullWidth/>
      <TextInput source="category" fullWidth/>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <TextField source="id" />
    </SimpleForm>
  </Edit>
);
