import React from "react";
import {
  Datagrid,
  DateField,
  Identifier,
  List,
  Record,
  ReferenceField,
  TextField,
} from "react-admin";
import { useHistory } from "react-router-dom";

export const LogTraceList = (props: any) => {
  const history = useHistory();

  const rowClick = (id: Identifier, basePath: string, record: Record) => {
    history.push(`/logs?trace=${record.id}`);
    return "";
  };

  return (
    <List bulkActionButtons={false} sort={{ field: 'minTimestamp', order: 'DESC' }} {...props}>
      <Datagrid rowClick={rowClick}>
        <ReferenceField source="userId" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <DateField source="minTimestamp" showTime />
        <DateField source="maxTimestamp" showTime />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}
